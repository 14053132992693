import React, { useEffect, useState } from "react";
import "./photos.css";
import Bikes from "../../assets/Bikes.jpg";
import Hamburg_Rathaus from "../../assets/Hamburg_Rathaus.jpg";
import Hannover_Rathaus from "../../assets/Hannover_Rathaus.jpg";
import Mallorca_Church from "../../assets/Mallorca_Church.jpg";
import IRC_JUB from "../../assets/IRC_JUB.jpg";

const Slide = ({ src }, { alt }) => {
  return (
    <div className="slide">
      <img src={src} alt={alt} height="100%" width="100%" />
    </div>
  );
};

const Photos = () => {
  const [currentSlide, setCounter] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => {
        const nextCounter = prevCounter >= 5 ? 1 : prevCounter + 1;
        document.getElementById(`radio${nextCounter}`).checked = true;
        return nextCounter;
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const handleManualNavigation = (slideNumber) => {
    setCounter(slideNumber);
    document.getElementById(`radio${slideNumber}`).checked = true;
  };

  // only for auto navigation
  //   const [currentSlide, setCurrentSlide] = useState(1);

  //   // function to update the slide
  //   const updateSlide = () => {
  //     setCurrentSlide((currentSlide) => (currentSlide % 5) + 1);
  //   };

  //   // run the function every 5 seconds
  //   useEffect(() => {
  //     const interval = setInterval(() => {
  //       updateSlide();
  //     }, 5000);
  //     return () => clearInterval(interval);
  //   }, []);

  return (
    <>
      <div className="slider">
        <div className="slides">
          <input
            type="radio"
            name="radio-btn"
            id="radio1"
            checked={currentSlide === 1}
          />
          <input
            type="radio"
            name="radio-btn"
            id="radio2"
            checked={currentSlide === 2}
          />
          <input
            type="radio"
            name="radio-btn"
            id="radio3"
            checked={currentSlide === 3}
          />
          <input
            type="radio"
            name="radio-btn"
            id="radio4"
            checked={currentSlide === 4}
          />
          <input
            type="radio"
            name="radio-btn"
            id="radio5"
            checked={currentSlide === 5}
          />

          {/* <Slide src={Bikes} alt="IRC JUB" /> */}
          <div className="slide first">
            <img src={IRC_JUB} alt="IRC JUB" height="100" />
          </div>
          <Slide src={Hamburg_Rathaus} alt="Hamburg Rathaus" />
          <Slide src={Hannover_Rathaus} alt="Hannover Rathaus" />
          <Slide src={Mallorca_Church} alt="Mallorca Church" />
          <Slide src={Bikes} alt="Bikes" />

          {/* <div className="slide">
          <img src={Hamburg_Rathaus} alt="Hamburg Rathaus" />
        </div>
        <div className="slide">
          <img
            src={Hannover_Rathaus}
            alt="Hannover Rathaus"
          />
        </div>
        <div className="slide">
          <img src={Mallorca_Church} alt="Mallorca Church" />
        </div>
        <div className="slide">
          <img src={Bikes} alt="Bikes" />
        </div> */}

          <div className="navigation-auto">
            <div className="auto-btn1"></div>
            <div className="auto-btn2"></div>
            <div className="auto-btn3"></div>
            <div className="auto-btn4"></div>
            <div className="auto-btn5"></div>
          </div>

          <div className="navigation-manual">
            <label
              htmlFor="radio1"
              className="manual-btn"
              onClick={() => handleManualNavigation(1)}
            ></label>
            <label
              htmlFor="radio2"
              className="manual-btn"
              onClick={() => handleManualNavigation(2)}
            ></label>
            <label
              htmlFor="radio3"
              className="manual-btn"
              onClick={() => handleManualNavigation(3)}
            ></label>
            <label
              htmlFor="radio4"
              className="manual-btn"
              onClick={() => handleManualNavigation(4)}
            ></label>
            <label
              htmlFor="radio5"
              className="manual-btn"
              onClick={() => handleManualNavigation(5)}
            ></label>
          </div>
        </div>
      </div>
      {/* add more photos button to add more photos here  */}
    </>
  );
};

export default Photos;
