import React from "react";
import "./home.css";
import Social from "./Social";
// import Suraj from "../../assets/Suraj_Giri.png";
// import Suraj from "../../assets/Suraj_Giri.jpeg";
import Suraj from "../../assets/Suraj.jpg";
// import Suraj_Graduation from "../../assets/Suraj_Graduation.jpg";
import Photos from "./Photos";
import ScrollButton from "./Scroll";

const Home = () => {
  return (
    <header id="home">
      {/* <div className="container home_container"> */}
      <div className="row_intro">
        <div className="col_name_photo">
          <div className="col_photo">
            <img src={
              Suraj
            } alt="me" />
          </div>
          <h4>Hello I'm</h4>
          <h1>Suraj Giri</h1>
          <h4 className="text_light">Student, Software Engineer, Machine Learning Enthusiast</h4>
          <Social />
        </div>

        <div className="col_intro_with_scroll_btn">
          <div className="col_intro">
            <div className="intro_header">
              <h2>Intro: </h2>
            </div>
            <div className="intro_desc">
              <h3>
                Hello! I am Suraj Giri, an MSc. Informatics student at the
                University of Bonn, specializing in Machine Learning and
                Intelligent Systems. I hold a Bachelor's in Computer Science
                from Constructor University Bremen. As a tech enthusiast from
                Nepal, I have experience in software engineering, cloud
                platforms, and data engineering. I am passionate about
                leveraging cutting-edge technologies to solve complex problems.
                Outside of tech, I enjoy traveling, trekking, cycling, and
                watching sunsets.
              </h3>
            </div>
          </div>
          <div className="scroll_button">
            <span role="button">
              <ScrollButton />
            </span>
          </div>
        </div>
      </div>

      <div className="row_photos">
        <div className="col_photo_loop">
          <Photos />
        </div>
      </div>
      {/* </div> */}
    </header>
  );
};

// const scrollNext100vh = () => {
//   const windowHeight = window.innerHeight;
//   const scrollAmount = windowHeight * 1; // 1 for 100vh, 2 for 200vh, and so on

//   window.scrollBy(0, scrollAmount);
// }

export default Home;
