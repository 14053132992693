import React from "react";
import "./about.css";
import { AiOutlineFileText } from "react-icons/ai";
import Suraj_Giri_Resume from "../../assets/Suraj_Giri_CV.pdf";

const About = () => {
  return (
    <section id="about">
      <div className="about">
        <div className="about_with_everything">
          <div className="about_me">
            <h1>About Me</h1>
          </div>
          <div className="resume">
            <a
              href={Suraj_Giri_Resume}
              role="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume <AiOutlineFileText />
            </a>
          </div>
          <div className="row_about_me_n_resume"></div>

          <div className="row">
            <div className="row_header">
              <h3>Education</h3>
            </div>

            <div className="row_desc">
            <div class="card">
                <div class="card-header">
                  Masters of Science in Computer Science{" "}
                  <div className="card_subheader">
                    2023-2025(Expected) | Rhenish Friedrich Wilhelm University of Bonn
                  </div>
                </div>
                <p>
                  <ul>
                    <li>Specialization: Machine Learning and Intelligent Systems</li>
                  </ul>
                </p>
              </div>
              <div class="card">
                <div class="card-header">
                  Bachelors of Science in Computer Science{" "}
                  <div className="card_subheader">
                    2020-2023 | Constructor University (previously Jacobs
                    University Bremen)
                  </div>
                </div>
                <p>
                  <ul>
                    <li>Minor in Robotics and Intelligent Systems</li>
                    <li>BSc. Thesis: Towards CI/CD for Trajectory-Based Molecular Machine Learning using Git and DVC</li>
                  </ul>
                  {/* <ul>
                    <li>
                      Software Engineering
                      <br />
                    </li>
                    <li>
                      Databases and Web Services
                      <br />
                    </li>
                    <li>
                      Operating Systems
                      <br />
                    </li>
                    <li>
                      Machine Learning
                      <br />
                    </li>
                    <li>
                      Algorithms and Data Structures
                      <br />
                    </li>
                    <li>
                      Programming in C and C++
                      <br />
                    </li>
                    <li>
                      Automata Computability and Complexity
                      <br />
                    </li>
                    <li>
                      Distributed Algorithms
                      <br />
                    </li>
                    <li>
                      Computer Vision
                      <br />
                    </li>
                    <li>
                      Computer Networks
                      <br />
                    </li>
                    <li>
                      Artificial Intelligence
                      <br />
                    </li>
                    <li>Stochastic Methods Lab</li>
                  </ul> */}
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="row_header">
              <h3>Experiences</h3>
            </div>

            <div className="row_desc">
              <div class="card">
                <div class="card-header">
                  Software Engineer - Working Student{" "}
                  <div className="card_subheader">
                    niologic GmbH, Hürth, Germany | 16 OCT 2023 – CURRENT
                  </div>
                </div>
                <p>
                  <ul>
                    <li>
                      Collaborated on 'Premergency', a location intelligence
                      application for fire departments and ambulance services,
                      ensuring its reliability and scalability through
                      troubleshooting, feature implementation, and bug fixes.
                      <br />
                    </li>
                    <li>
                      Designed a prototype for a pivotal client project in
                      collaboration with team members.
                      <br />
                    </li>
                    <li>
                      Facilitated project migration to Google Cloud Services
                      alongside a dedicated team.
                      <br />
                    </li>
                    <li>
                      Worked with Data Build Tool (dbt) while collaborating on a
                      price optimization project.
                      <br />
                    </li>
                  </ul>
                </p>
              </div>
              <div class="card">
                <div class="card-header">
                  Software Developer - Working Student{" "}
                  <div className="card_subheader">
                    elipZis GmbH, Bremen, Germany | 1 SEP 2022 – 31 AUG 2023
                  </div>
                </div>
                <p>
                  <ul>
                    <li>
                      Collaborated in designing and developing projects using
                      JavaScript, TypeScript, and Solidity leading to token
                      creation, minting, and burning, while currently trying to
                      develop a smart contract for secure token transfer within
                      the wallets.
                      <br />
                    </li>
                    <li>
                      Assisted the Software Development team to design and
                      develop a mobile application for a web-based game using
                      Unity, C#, and PHP, contributing to the development and
                      testing of new features while ensuring optimal performance
                      and user experience.
                      <br />
                    </li>
                    <li>
                      Conducted bug fixes, preparing technical documentation,
                      and identifying and implementing solutions to improve code
                      quality and optimize performance to facilitate efficient
                      development and maintenance of the application.
                      <br />
                    </li>
                    <li>
                      Utilized version control systems such as Bitbucket and
                      GitHub, as well as tools like Jira and Slack to facilitate
                      efficient development and collaboration.
                      <br />
                    </li>
                  </ul>
                </p>
              </div>
              <div class="card">
                <div class="card-header">
                  Bachelors Thesis: "Towards CI/CD for Trajectory-Based
                  Molecular Machine Learning using Git and DVC"{" "}
                  <div className="card_subheader">
                    Prof. Dr. Peter Zaspel - Constructor University (formerly
                    Jacobs University Bremen), Bremen, Germany | 1 FEB 2023 – 16
                    MAY 2023
                  </div>
                </div>
                <p>
                  <ul>
                    <li>
                      Developed a Kernel Ridge Regression model to calculate the
                      excited energy of Benzene molecules based on trajectories.
                      <br />
                    </li>
                    <li>
                      Implemented a streamlined pipeline using Git and Data
                      Version Control (DVC) to optimize experimentation,
                      parameter tuning, and model selection for trajectory-based
                      molecular machine learning. Explored the utilization of
                      DVC's caching feature for efficient resource utilization
                      and enabled collaborative, efficient, and tracked pipeline
                      changes among team members.
                      <br />
                    </li>
                  </ul>
                </p>
              </div>
              <div class="card">
                <div class="card-header">
                  Teaching Assistant for Software Engineering{" "}
                  <div className="card_subheader">
                    Constructor University (formerly Jacobs University Bremen),
                    Bremen, Germany | 15 FEB 2023 – 31 MAY 2023
                  </div>
                </div>
                <p>
                  <ul>
                    <li>
                      Successfully supervised and provided guidance to a class
                      of approximately 140 students, including conducting
                      interactive tutorials. contract for secure token transfer
                      within the wallets.
                      <br />
                    </li>
                    <li>
                      {/* •  */}
                      Evaluated assignments, provided constructive feedback, and
                      contributed to effective student engagement and
                      understanding.
                      <br />
                    </li>
                  </ul>
                </p>
              </div>
              <div class="card">
                <div class="card-header">
                  Intern / Working Student{" "}
                  <div className="card_subheader">
                    Eurofins LFIT Application Development, Hamburg, Germany | 1
                    JUN 2022 – 31 AUG 2022
                  </div>
                </div>
                <p>
                  <ul>
                    <li>
                      Achieved a 68% reduction in runtime costs for an
                      automation project for Tax Department by identifying
                      inefficiencies and implementing optimization measures in
                      collaboration with a supervisor.
                      <br />
                    </li>
                    <li>
                      Collaborated with a team to improve database performance
                      by developing and implementing efficient data queries, and
                      contributed to a Robotic Process Automation project, using
                      Python(Selenium) and Power Automate, for efficient and
                      automated file access and calculations across various
                      servers.
                      <br />
                    </li>
                    <li>
                      Utilized Microsoft Azure DevOps Repos for version control
                      and Microsoft Azure Boards for efficient project tracking.
                      <br />
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
